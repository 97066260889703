html, body {
  height: 100%;
  background: radial-gradient(circle, #031b0d 0%, #0d3c19 100%);
  margin: 0;
  font-weight: 400;
  transition: background 1s;
  font-family: Lato, sans-serif !important;
}

canvas[resize] {
  width: 100%;
  height: 100%;
}

hr {
  border: .5px solid;
}

#start-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  opacity: .5;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  margin: 4px 2px;
  padding: 15px 32px;
  font-size: 16px;
  text-decoration: none;
  transition: opacity .5s;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#start-button:hover {
  cursor: default;
}

#info-icon {
  opacity: .3;
  width: 30px;
  z-index: 1000;
  transition: opacity .3s;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#info-icon:hover {
  opacity: 1;
}

#socials, #credits-close {
  z-index: 100;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 30px;
  position: fixed;
  top: 0;
  right: 0;
}

#socials-content {
  flex-direction: column;
  display: flex;
}

#socials i {
  margin-bottom: 5px;
}

#credits, #poems {
  text-align: center;
  color: #333;
  opacity: 1;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #709f7d;
  font-size: 1.3em;
  font-weight: 300;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#credits > .inner {
  background: #709f7d;
}

#poems {
  color: #194524;
  background: #eaeaea;
  top: 47%;
}

#poems h3 {
  margin-top: 40px;
  margin-bottom: 4px;
}

#poems p {
  font-size: 1rem;
}

#credits-close {
  color: #d3d3d3;
}

.right {
  text-align: right;
}

#poems > .inner {
  text-align: left;
  background-image: url("paper.925971c6.png");
  background-repeat: repeat;
  padding: 0 50px 30px;
}

#poems hr {
  width: 80%;
  border: 1px solid;
  margin-top: 30px !important;
}

.inner {
  height: auto;
  position: relative;
}

.full-height {
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#poems-arrow:hover, #credits-close:hover, #socials-close:hover, #info-icon:hover {
  cursor: pointer;
}

#credits hr {
  width: 80%;
  color: #1b391b;
  border: .9px solid;
}

#credits .green {
  color: #1b391b;
  font-size: 1.2rem;
}

.no-style, .no-style-icon {
  color: #1b391b;
  text-decoration: none;
}

.no-style:hover {
  text-decoration: underline;
}

.no-style-icon:hover {
  text-decoration: none;
}

.lds-grid {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-grid div {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  animation: 1.2s linear infinite lds-grid;
  position: absolute;
}

.lds-grid div:nth-child(1) {
  animation-delay: 0s;
  top: 8px;
  left: 8px;
}

.lds-grid div:nth-child(2) {
  animation-delay: -.4s;
  top: 8px;
  left: 32px;
}

.lds-grid div:nth-child(3) {
  animation-delay: -.8s;
  top: 8px;
  left: 56px;
}

.lds-grid div:nth-child(4) {
  animation-delay: -.4s;
  top: 32px;
  left: 8px;
}

.lds-grid div:nth-child(5) {
  animation-delay: -.8s;
  top: 32px;
  left: 32px;
}

.lds-grid div:nth-child(6) {
  animation-delay: -1.2s;
  top: 32px;
  left: 56px;
}

.lds-grid div:nth-child(7) {
  animation-delay: -.8s;
  top: 56px;
  left: 8px;
}

.lds-grid div:nth-child(8) {
  animation-delay: -1.2s;
  top: 56px;
  left: 32px;
}

.lds-grid div:nth-child(9) {
  animation-delay: -1.6s;
  top: 56px;
  left: 56px;
}

@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

small {
  font-size: 1rem;
}

/*# sourceMappingURL=index.ce42aa8e.css.map */
