html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400;
  /* overflow: hidden; */
  transition: background 1s;

  /* background: black; */

  /* background: rgb(3, 27, 13); */
  background: radial-gradient(
    circle,
    rgba(3, 27, 13, 1) 0%,
    rgba(13, 60, 25, 1) 100%
  );

  /* background: radial-gradient(#e66465, #9198e5); */
  /* background: radial-gradient(#e66465, #9198e5); */
}

/* Scale canvas with resize attribute to full size */
canvas[resize] {
  width: 100%;
  height: 100%;
}

hr {
  border: 0.5px solid;
}

#start-button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  /* background-color: rgb(237, 254, 234) red; */
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  opacity: 0.5;
  transition: opacity 0.5s;
}

#start-button:hover {
  cursor: default;
}

#info-icon {
  position: fixed;
  right: 10px;
  bottom: 10px;
  opacity: 0.3;
  transition: opacity 0.3s;
  width: 30px;
  z-index: 1000;
}

#info-icon:hover {
  opacity: 1;
}

#socials,
#credits-close {
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 30px;
  z-index: 100;
}

#socials-content {
  display: flex;
  flex-direction: column;
}

#socials i {
  margin-bottom: 5px;
}

#credits,
#poems {
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
  font-size: 1.3em;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: rgba(200, 200, 200, 0.8);
  background: rgb(112 159 125);
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  color: white;
  font-weight: 300;
}

#credits > .inner {
  background: rgb(112 159 125);
}

#poems {
  top: 47%;
}

#poems {
  background: rgb(234 234 234);
  color: rgb(25 69 36);
}

#poems h3 {
  margin-top: 40px;
  margin-bottom: 4px;
}

#poems p {
  font-size: 1.0rem;
}

#credits-close {
  color: lightgrey;
}

.right {
  text-align: right;
}

#poems > .inner {
  padding: 0 50px;
  padding-bottom: 30px;
  text-align: left;
  background-image: url('img/paper.png');
  background-repeat: repeat;
}

#poems hr {
  width: 80%;
  border: 1px solid;
  margin-top: 30px !important;
}

.inner {
  position: relative;
  height: auto;
}
.full-height {
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#poems-arrow:hover,
#credits-close:hover,
#socials-close:hover,
#info-icon:hover {
  cursor: pointer;
}

#credits hr {
  width: 80%;
  color: rgb(27 57 27);
  border: 0.9px solid;
}

#credits .green {
  color: rgb(27 57 27);
  font-size: 1.2rem;
}

.no-style,
.no-style-icon {
  color: rgb(27 57 27);
  text-decoration: none;
}

.no-style:hover {
  text-decoration: underline;
}

.no-style-icon:hover {
  text-decoration: none;
}

/* ------------------- */

.lds-grid {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

small {
  font-size: 1rem;
}
